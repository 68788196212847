<template>
  <section v-if="articles && !slice.primary.hide_section" class="home__articles large-pad">
    <div class="home__articles__top row">
      <div class="home__articles__top__left column small-7">
        <h2 class="h5">{{ slice.primary.title | getTitle }}</h2>
      </div>
      <div class="home__articles__top__right column small-5">
        <ArrowLink
          :link="slice.primary.link"
          to="/beauty-edit"
          :text="slice.primary.link_text ? slice.primary.link_text : 'Read All'"
        />
      </div>
    </div>
    <div class="home__articles__middle row">
      <div class="home__articles__middle__left column small-12 medium-10 large-8 xlarge-4 xxlarge-3">
        <h2 class="h5">{{ slice.primary.title | getTitle }}</h2>
        <h3 v-if="slice.primary.subtitle" class="h1">
          <prismic-rich-text :field="slice.primary.subtitle" />
        </h3>
        <prismic-rich-text v-if="slice.primary.description" :field="slice.primary.description" />
        <ArrowLink
          :link="slice.primary.link"
          to="/beauty-edit"
          :text="slice.primary.link_text ? slice.primary.link_text : 'Read All'"
        />
      </div>
      <div class="home__articles__middle__right column small-12 xlarge-8 xxlarge-9">
        <div class="row article-cards">
          <div v-for="article in articles" class="column small-12 large-6">
            <ArticleCard
              :article="article"
              :showAuthor="false"
              imageWidth="715"
              imageHeight="480"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex';

  import { fetchArticlesByUIDs } from '@/prismic/queries';

  import ArticleCard from '@/components/global/ArticleCard';
  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'HomepageArticles',

    components: {
      ArticleCard,
      ArrowLink
    },

    data() {
      return {
        manualArticles: null
      }
    },

    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    },

    computed: {
      ...mapState({
        learnLinks: state => state.header.links.learn
      }),
      manualArticleUids() {
        let manual = [];

        if(this.slice.primary.article_1.uid) {
          manual.push(this.slice.primary.article_1.uid);
        }

        if(this.slice.primary.article_2.uid) {
          manual.push(this.slice.primary.article_2.uid);
        }

        return manual;
      },
      latestArticles() {
        return this.learnLinks.find(link => link.type == 'blog').articles;
      },
      articles() {
        const allArticles = this.manualArticles ?
          this.manualArticles.concat(this.latestArticles) :
          this.latestArticles;

        return allArticles ? allArticles.slice(0, 2) : null;
      }
    },

    async fetch() {
      if(this.manualArticleUids.length) {
        try {
          const { data } = await this.$apolloProvider.defaultClient.query({
            query: fetchArticlesByUIDs,
            variables: {
              uids: this.manualArticleUids
            }
  		    });

          const response = data.allArticles.edges;

          if(response.length) {
            this.manualArticles = response.map(item => item.node);
          }
        } catch(err) {
          console.log(err);
        }
      }
    }
  }
</script>
